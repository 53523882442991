<template>
  <template>
    <span v-show="false">
      {{ setupRoleandName() }}
    </span>
  </template>

  <div v-if="!isAuthenticated && !isLoading" class="top-bar-wrap">
    <div class="topbar">
      <div class="mainlogo">
        <img class="app-logo" src="../assets/img/protractorNew2.svg" alt="Protractor">
      </div>
      <Menubar>
        <template #start>
          <img alt="logo" class="logo" src="../assets/img/protractorCompass_revNew2.svg" height="50">
        </template>
        <template #end>
          <span class="p-menubar-list">
            <Button class="p-menubar-item" label="Login" @click="login" />
          </span>
        </template>
      </Menubar>
    </div>
  </div>

  <div v-else-if="isAuthenticated" class="top-bar-wrap">
    <div :class="['topbar', 'auth', role]">
      <div class="mainlogo">
        <h2>Manager Client</h2>
      </div>
      <Menubar>
        <template #start>
            <img alt="logo" class="logo" src="../assets/img/protractorCompass_revNew2.svg" height="50">
            <div class="p-menubar-list">
              <Button class="p-menubar-item" v-for="item in getMenuItems()" @click="(event) => navigateTo(event, item.path)" :key="item.index">
                {{ item.label }}
              </Button>
            </div>
        </template>
        <template #end>
          <img :src="user?.picture" @click="() => { visible = true;}"  width="50" alt="User's profile picture" class="border-circle img-fluid profile-picture" />
          <Dialog class="profile" v-model:visible="visible" modal header="Profile">
            <div class="image">
              <img :src="user?.picture" width="75" alt="User's profile picture" class="border-circle img-fluid profile-picture" />
            </div>
            <div class="info">
              <h2>{{ user?.name }}</h2>
              <p class="lead text-muted"><strong>Email:</strong> {{ user?.email }}</p>
              <p class="lead text-muted"><strong>Store name:</strong> {{ storename }}</p>
              <p class="lead text-muted"><strong>User role:</strong> {{ role }}</p>
              <Button label="log out" class="logout" @click="logout();" />
            </div>
          </Dialog>
        </template>
      </Menubar>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
import ApiService from '../service/api.service';
import { ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useRouter } from 'vue-router';

const auth0 = useAuth0();
const router = useRouter();
const visible = ref(false);
const role = ref('');
const storename = ref('');
const isAuthenticated = ref(auth0.isAuthenticated);
const isLoading = ref(auth0.isLoading);
const user = ref(auth0.user);

const supportAdminMenu = ref([
  {
    label: 'Stores',
    path: '/stores'
  },
  {
    label: 'StoreAdmin Users',
    path: '/adminusers'
  }
]);

const storeAdminMenu = ref([
  {
    label: 'Store Users',
    path: '/storeusers'
  }
])

/**
 * Menu list by role, new roles can be added here
 */
const menus = {
  StoreAdmin: storeAdminMenu.value,
  SupportAdmin: supportAdminMenu.value
};

const logout = () => {
  auth0.logout({
    returnTo: window.location.origin
  });
};

const login = () => {
  auth0.loginWithRedirect()
}

/**
 * Actual fetching of role and name
 * Note that useAuth0 doesn't work properly on onMounted and onBeforeMount
 * isAuthenticated always return false if used by native vue hooks
 */
const setupRoleandName = async () => {
  if (isAuthenticated.value === true && role.value == '' && storename.value == '') {
    const auth0 = useAuth0();
    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    role.value = await ApiService.getVuexRole(accessToken);
    storename.value = await ApiService.getStoreName(accessToken, storeid);
  }
  return;
}

/**
 * Menu items dynamically by role
 */
const getMenuItems = () => {
  return menus[role.value];
}

/**
 * Navigate to different pages
 * @param event 
 * @param path 
 */
 const navigateTo = (event: MouseEvent, path: string) => {
    event.preventDefault();
    router.push(path);
}
</script>

