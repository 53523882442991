<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar />
    <!-- <div class="container flex-grow-1"> -->
    <div class="outer">
      <error />
      <div>
        <router-view />
      </div>
    </div>
    <Footer class="footer text-center"></Footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";
import Footer from "./components/Footer";
export default {
  components: {
    NavBar,
    Error,
    Footer
  }
};
</script>
