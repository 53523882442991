<template>
    <div>
        <p>
            Protractor Manager Client | Version {{ version }}
        </p>
    </div>
</template>
  
<script lang="ts" setup>
const version = process.env.VUE_APP_VERSION;
</script>