import { GetterTree } from 'vuex'
import { State, WOItem } from './state'

// Getters types
export type Getters = {
  getCurrentWO(state:State): WOItem|undefined;
};

//getters

export const getters: GetterTree<State, State> & Getters = {
  getCurrentWO: (state:State) :WOItem|undefined=> {
    
    return state.currentwoitem;
  }
};