/* eslint-disable */
import { MutationTree } from 'vuex'
import { State, WOItem } from './state'

export enum MutationTypes {
  SET_STOREID = "SET_STOREID",
  SET_ROLE = "SET_ROLE",
  SET_WOITEMS="SET_WOITEMS",
  UPDATE_VIN="UPDATE_VIN",
  SET_CURRENTWO_BYID="SET_CURRENTWO_BYID"
}


//Mutation Types
export type Mutations = {
  [MutationTypes.SET_STOREID](state: State, payload: string): void;
  [MutationTypes.SET_ROLE](state: State, payload: string): void;
  [MutationTypes.SET_WOITEMS](state: State, woitems: WOItem[]): void;
  [MutationTypes.UPDATE_VIN](state: State, newItem: any): void;
  [MutationTypes.SET_CURRENTWO_BYID](state: State, newItemid: any): void;
};

//define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_STOREID](state: State, payload: string) {
   
    state.storeid = payload;
  },
  [MutationTypes.SET_ROLE](state: State, payload: string) {
   
    state.role = payload;
  },
  [MutationTypes.SET_WOITEMS](state: State, woitems: WOItem[]) {
    state.woitems = woitems
  },
  [MutationTypes.UPDATE_VIN](state: State, newItem: any) {
    /*
    const itemIndex = state.woitems.findIndex(s => s.ServiceItemID === newItem.ServiceItemID)
    if (itemIndex === -1) return
    state.woitems[itemIndex].VIN = newItem.VIN;
    */
    const items= state.woitems.filter(s => s.ServiceItemID === newItem.ServiceItemID)
    for (const item of items) {
      item.VIN=newItem.VIN;
    }
  },
  [MutationTypes.SET_CURRENTWO_BYID](state: State, newItemid: any) {
    state.currentwoitem = state.woitems.find((item) => item.WorkOrderNumber ==newItemid);

  },


};