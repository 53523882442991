import { createRouter as createVueRouter, createWebHistory, Router } from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import StoreDisable from "../views/StoreDisable.vue";
import Stores from "../views/Stores.vue";
import StoreUsers from "../views/StoreUsers.vue";
import AdminUsers from "../views/AdminUsers.vue";

import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from 'vue';

export function createRouter(app: App): Router {
  return createVueRouter({
    routes: [
      {
        path: "/",
        name: "home",
        component: Home
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/storedisable",
        name: "storedisable",
        component: StoreDisable,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/stores",
        name: "stores",
        component: Stores,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/storeusers",
        name: "storeusers",
        component: StoreUsers,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/adminusers",
        name: "adminusers",
        component: AdminUsers,
        beforeEnter: createAuthGuard(app)
      }

    ],
    history: createWebHistory()
  });
}
