export type WOItem = {
  WorkOrderNumber: number
  ServiceItemID: string
  FirstName: string
  LastName: string
  Make:string
  Model: string
  VIN: string
  Color: string
  Plate: string
}

export type State = {
  storeid: string
  role:string
  woitems: WOItem[]
  currentwoitem:WOItem|undefined
}

export const state: State = {
  storeid: '0',
  role: '',
  woitems: [],
  currentwoitem:{} as WOItem
}