<template>

  <div class="content">

    <Card>
      <template #title>Welcome to <br /><strong>Manager Client</strong></template>
      <template #content>
        <p v-if="!isAuthenticated" class="m-4">Please Login</p>
      </template>
    </Card>

  </div>

</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: "HomeContent",
  setup() {
    const auth0 = useAuth0();
    return {
      isAuthenticated: auth0.isAuthenticated,
    };
  }
};
</script>
