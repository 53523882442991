import { useStore } from "../store";
import { MutationTypes } from '../store/mutations';

const ApiService = {
  async getStoreid(accessToken: string) {

    let storeid = localStorage.getItem('storeid') as string;
    if (storeid != null)
      return storeid;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();
    // console.log(data["http://protractor.com/storeid"]);

    storeid = data["http://protractor.com/storeid"];
    localStorage.setItem('storeid', storeid);

    return storeid;
  },
  async getVuexStoreid(accessToken: string) {
    const store = useStore();

    let storeid = store.state.storeid;
    if (storeid != '0')
      return storeid;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();
    // console.log(data["http://protractor.com/storeid"]);

    storeid = data["http://protractor.com/storeid"];
    store.commit(MutationTypes.SET_STOREID, storeid);

    return storeid;
  },

  async getVuexRole(accessToken: string) {
    const store = useStore();

    let role = store.state.role;
    if (role != '')
      return role;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();
    // console.log(data["http://protractor.com/role"]);

    role = data["http://protractor.com/role"];
    store.commit(MutationTypes.SET_ROLE, role);

    return role;
  },

  async getRole(accessToken: string) {
    let role = localStorage.getItem('role') as string;
    if (role != null)
      return role;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();

    role = data["http://protractor.com/role"];
    localStorage.setItem('role', role);

    return role;
  },

  async getStoreStatus(accessToken: string,storeid: string) {


    const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + "stores/" + storeid, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    const storestatus = data.message.storestatus;
    //console.log("storestatus is :"+storestatus);
    return storestatus;
  },

  async getStoreName(accessToken: string,storeid: string) {


    const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + "stores/" + storeid, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    const storename: string = data.message.storename;

    return storename;
  }
};

export default ApiService;