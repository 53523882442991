<template>
	<ErrorModal />
	<Toast />
	<ConfirmDialog></ConfirmDialog>

	<div class="card-container">

		<div class="top">
			<div class="head">
				<h2 class="header"><strong>{{storename}}</strong> Users</h2>
			</div>
		</div>
		
		<div class="bottom">
			<div class="search">
				<span class="p-input-icon-left field-container">
					<i class="pi pi-search" />
					<InputText v-model="filters['global'].value" placeholder="Search" />
					<Button type="button" icon="pi pi-times" class="p-button-text clear" @click="clearFilter()" />
				</span>
			</div>
			<div class="create">
				<Button label="Create User" class="p-button-rounded btn-create" @click="openNew" />
			</div>
		</div>

	</div>

	<div class="container flex-grow-1 users">

		<DataTable ref="dt" :value="users" v-model:selection="selectedUsers" dataKey="id" :paginator="true"
			:rows="10" :filters="filters" stripedRows responsiveLayout="stack" breakpoint="640px"
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
			:rowsPerPageOptions="[5, 10, 25]"
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users" :class="block" >
			<Column field="name" header="Name" :sortable="true"></Column>
			<Column field="role" header="Role" :sortable="true" class="role">
				<template #body="{data}">
					<span :class="'customer-badge status-' + data.role">{{data.role}}</span>
				</template>
			</Column>
			<Column field="blocked" class="status" header="Blocked?" :sortable="true">
				<template #body="slotProps">
					<Button label="Yes" class="p-button-rounded p-button-danger btn-blocked" :class="slotProps.data.blocked" @click="blockunblockUser(slotProps.data)" v-if="slotProps.data.blocked=='true'" />
					<Button label="No"  class="p-button-rounded p-button-success btn-blocked" :class="slotProps.data.blocked" @click="blockunblockUser(slotProps.data)" v-else/>
				</template>
			</Column>
			<Column field="view" header=" " :sortable="false" class="view">
				<template #body="slotProps">
					<Button label="View" class="p-button-rounded btn-view secondary" @click="viewUser(slotProps.data)" />
					<Button label="Edit" class="p-button-rounded btn-edit secondary" @click="editUser(slotProps.data)" />
				</template>
			</Column>
			<Column field="view" header=" " :sortable="false" class="password">
				<template #body="slotProps">
					<Button label="Change Password" class="p-button-rounded btn-password secondary" @click="changeUserPassword(slotProps.data)" />
				</template>
			</Column>

		</DataTable>


		<Dialog v-model:visible="userDialog" :header="createORedit" :modal="true" class="p-fluid">

			<div class="field">
				<label for="name">Name<span class="req">*</span></label>
				<InputText id="name" v-model.trim="user.name" required="true"
					:class="{ 'p-invalid': submitted && !user.name }" />
				<span class="error" v-if="vv$.name.$error">{{ vv$.name.$errors[0].$message }}</span>
			</div>

			<div class="field">
				<label for="email">Email<span class="req">*</span></label>
				<InputText id="email" v-model.trim="user.email" required="true"
					:class="{ 'p-invalid': submitted && !user.email }" />
				<span class="error" v-if="vv$.email.$error">{{ vv$.email.$errors[0].$message }}</span>
			</div>

			<div class="field" v-if="!user.user_id">
				<label for="password">New Password<span class="req">*</span></label>
				<Password id="password" v-model.trim="password" required="true"
					:class="{ 'p-invalid': submitted && !password }" />
				<span class="error" v-if="v$.password.$error">{{ v$.password.$errors[0].$message }}</span>
			</div>
			<div class="field" v-if="!user.user_id">
				<label for="password2">Confirm password<span class="req">*</span></label>
				<Password id="password2" v-model.trim="password2" required="true"
					:class="{ 'p-invalid': submitted && !password2 }" />
				<span class="error" v-if="v$.password2.$error">{{ v$.password2.$errors[0].$message }}</span>
			</div>

			<div class="field">
				<label for="role">Role<span class="req">*</span></label>
				<Dropdown id="role" v-model="user.role" :options="roles" optionLabel="label" optionValue="value"
					placeholder="Select a Role" :class="{ 'p-invalid': submitted && !user.role }">
				</Dropdown>
				<span class="error" v-if="vv$.role.$error">{{ vv$.role.$errors[0].$message }}</span>
			</div>

			<div class="field">
				<label>Blocked<span class="req">*</span></label>
				<div class="formgrid grid">
					<div class="field-radiobutton col-12">
						<InputSwitch v-model="user.blockedbool" style="border-radius: 1.5em;"/>
						<label for="status">{{ user.blockedbool ? 'Yes' : 'No' }}</label>
					</div>
				</div>
			</div>

			<template #footer>
				<Button label="Cancel" class="p-button-rounded btn-cancel secondary" @click="hideDialog" />
				<Button v-if="createORedit == 'Edit Store User'" label="Delete" class="p-button-rounded btn-save danger"
					@click="deleteUser()" />
				<Button label="Save" class="p-button-rounded btn-save primary" @click="saveUser" />
			</template>

		</Dialog>

		<Dialog v-model:visible="changeUserPasswordDialog" header="Change password"
			:modal="true" class="p-fluid">

			<div class="field">
				<label for="email">{{ user.email }}</label>

			</div>
			<div class="field">
				<label for="password">New Password<span class="req">*</span></label>
				<Password id="password" v-model.trim="password" required="true" :class="{ 'p-invalid': !password }" />
				<span class="error" v-if="v$.password.$error">{{ v$.password.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="password2">Confirm password<span class="req">*</span></label>
				<Password id="password2" v-model.trim="password2" required="true"
					:class="{ 'p-invalid': !password2 }" />
				<span class="error" v-if="v$.password2.$error">{{ v$.password2.$errors[0].$message }}</span>
			</div>

			<template #footer>
				<Button label="Cancel" class="p-button-rounded btn-cancel secondary" @click="changeUserPasswordDialog = false" />
				<Button label="Save" class="p-button-rounded btn-save primary" @click="savePassword" />
			</template>

		</Dialog>

		<Dialog v-model:visible="viewUserDialog" header="View User Details" :modal="true" class="p-fluid">

			<div class="data">
				<p><strong>Name:</strong><br /><span>{{user.name}}</span></p>
			</div>

			<div class="data">
				<p><strong>Email:</strong><br /><span>{{user.email}}</span></p>
			</div>



			<div class="data">
				<p><strong>Role:</strong><br /><span>{{user.role}}</span></p>
			</div>

			<div class="data">
				<p><strong>Blocked?:</strong><br />
					<span v-if="user.blocked=='true'" class="p-button-rounded p-button-danger btn-blocked">Yes</span>
					<span v-else label="No"  class="p-button-rounded p-button-success btn-blocked">No</span>
				</p>
			</div>

			<template #footer>
				<Button label="Cancel" class="p-button-rounded btn-cancel secondary" @click="hideViewDialog()" />
			</template>

		</Dialog>

	</div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount,onMounted, computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from 'primevue/usetoast';
import { useAuth0 } from "@auth0/auth0-vue";
import ApiService from '../service/api.service';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs, helpers } from '@vuelidate/validators';
import { useRouter } from 'vue-router';
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const auth0 = useAuth0();
const storeid = ref('');
const role = ref('');
const storestatus = ref('');
const storename = ref('');
const router = useRouter();
const createORedit = ref('');

onBeforeMount(async () => {
	const auth0 = useAuth0();
	const accessToken = await auth0.getAccessTokenSilently();
	storeid.value = await ApiService.getVuexStoreid(accessToken);
	role.value = await ApiService.getVuexRole(accessToken);
	storestatus.value = await ApiService.getStoreStatus(accessToken, storeid.value);
	storename.value = await ApiService.getStoreName(accessToken, storeid.value);

	if (storestatus.value == 'Disable'||role.value!='StoreAdmin') {
		router.push('/storedisable');
	}
	await getAllUserRoles(accessToken);
});

onMounted(async () => {
	const accessToken = await auth0.getAccessTokenSilently();
	const storeid = await ApiService.getVuexStoreid(accessToken);
	const Userurl = process.env.VUE_APP_NODEJS_BASE_URL + "managers/" + storeid;

	try {
		const response = await fetch(Userurl, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			}
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		users.value = data.message;
		users.value.map((item) => {
			item.blockedbool = item.blocked == 'true';
		});
	} catch (e) {
		openErrorModal(e.message);
	}
});

const toast = useToast();
const dt = ref();
const users = ref();
const userDialog = ref(false);
const changeUserPasswordDialog = ref(false);
const viewUserDialog = ref(false);
const user = ref({ name: '', email: '', role: '', blocked: '', blockedbool: false, user_id: '' });
const selectedUsers = ref();
const filters = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } });
const submitted = ref(false);
const password = ref('');
const password2 = ref('');
const roles = ref([]);

/**
 * Clear filter by initializing
 */
function clearFilter() {
  initFilters();
}

/**
 * Initialize filter
 */
function initFilters() {
  filters.value = { 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } };
}

/**
 * Form validation
 * @param value 
 */
function valid(value: string) {
	const containsUppercase = /[A-Z]/.test(value);
	const containsLowercase = /[a-z]/.test(value);
	const containsNumber = /[0-9]/.test(value);
	const containsSpecial = /[#?!@$%^&*-]/.test(value);
	return containsUppercase && containsLowercase && containsNumber && containsSpecial;
}

/**
 * Preparation of rules for password
 */
const rules = computed(() => ({
	password: { required: helpers.withMessage('Password cannot be empty', required), minLength: helpers.withMessage('Password must be at least 8 characters', minLength(8)), valid: helpers.withMessage('Password must contain uppercase, lowercase, number, special character', valid) },
	password2: { required: helpers.withMessage('Confirm password cannot be empty', required), sameAs: helpers.withMessage('Confirm password must be equal to password', sameAs(password)) },
}));

/**
 * Vuelidate initialization for password
 */
const v$ = useVuelidate(rules, { password, password2 });

/**
 * Preparation of rules for user
 */
const rulesv = computed(() => ({
	name: { required: helpers.withMessage('Name cannot be empty.', required) },
	email: { required: helpers.withMessage('Email already exists in the system.  Please use another.', required) },
	role: { required: helpers.withMessage('Role cannot be empty.', required) }
}));

/**
 * Vuelidate initialization for user
 */
const vv$ = useVuelidate(rulesv, user);

/**
 * Initialize data on create store user dialog
 */
function openNew() {
	user.value = { name: '', email: '',  role: '', blocked: '', blockedbool: false, user_id: '' };
	password.value = '';
	password2.value = '';
	vv$.value.$reset();
	submitted.value = false;
	userDialog.value = true;
	createORedit.value = "Create Store User";
}

/**
 * Hides the create user dialog box
 */
function hideDialog() {
	userDialog.value = false;
	submitted.value = false;
}

/**
 * Hide the view user dialog box
 */
function hideViewDialog() {
	viewUserDialog.value = false;
}

const confirm = useConfirm();

/**
 * Actual delete of user data
 */
async function deleteUser() {
	confirm.require({
		message: 'Do you want to delete the current user?',
		header: 'Please confirm Deletion',
		icon: 'pi pi-exclamation-triangle',
		acceptClass: 'p-button-danger',
		accept: async () => {
			const accessToken = await auth0.getAccessTokenSilently();
			const storeid = await ApiService.getVuexStoreid(accessToken);
			const url = process.env.VUE_APP_NODEJS_BASE_URL + "managers/" + storeid + "/" + user.value.user_id;
			try {
				const response = await fetch(url, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json'
					},
				});
				if (response.status >= 200 && response.status < 300) {
					//then delete user from array objects
					for (var i = 0; i < users.value.length; i++) {
						if (users.value[i].user_id === user.value.user_id) {
							users.value.splice(i, 1);
							i--;
						}
					}
					toast.add({ severity: 'error', summary: 'Confirmed', detail: 'User has been Deleted', life: 3000 });
				}
				const data = await response.json();
				if (response.status > 400) {
					throw new Error(JSON.stringify(data.error));
				}
			} catch (e) {
				openErrorModal(e.message);
			}
		},
		reject: () => {
			toast.add({ severity: 'error', summary: 'Rejected', detail: 'User Deletion cancelled.', life: 3000 });
		}
	});

	hideDialog();
}

/**
 * Actual saving and updating of user data
 */
async function saveUser() {
	submitted.value = true;
	if (user.value.email.trim()) { 
		if (user.value.user_id) {//update user
			users.value[findIndexById(user.value.user_id)] = user.value;
			let userdata = {
				name: user.value.name,
				email: user.value.email,
				role: user.value.role,
				blocked: user.value.blockedbool ? 'true' : 'false',
				password: 'none'
			};
			const accessToken = await auth0.getAccessTokenSilently();
			const storeid = await ApiService.getVuexStoreid(accessToken);
			const url = process.env.VUE_APP_NODEJS_BASE_URL + "managers/" + storeid + "/" + user.value.user_id;

			try {
				const response = await fetch(url, {
					method: 'PATCH',
					body: JSON.stringify(userdata),
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json'
					},
				});
				if (response.status >= 200 && response.status < 300) {
					toast.add({ severity: 'success', summary: 'Successful', detail: 'User has been updated', life: 3000 });
				}
				const data = await response.json();
				if (response.status > 400) {
					throw new Error(JSON.stringify(data.error));
				}
			} catch (e) {
				openErrorModal(e.message);
			}
		}
		else {// new user
			if (!await vv$.value.$validate()) return;
			if (!await v$.value.$validate()) return;
			let userdata = {
				name: user.value.name,
				email: user.value.email,
				role: user.value.role,
				blocked: user.value.blockedbool ? 'true' : 'false',
				password: password.value
			};
			const accessToken = await auth0.getAccessTokenSilently();
			const storeid = await ApiService.getVuexStoreid(accessToken);
			const url = process.env.VUE_APP_NODEJS_BASE_URL + "managers/" + storeid;

			try {
				const response = await fetch(url, {
					method: 'POST',
					body: JSON.stringify(userdata),
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/json'
					},
				});

				const data = await response.json();
				if (response.status > 400) {
					throw new Error(JSON.stringify(data.error));
				}
				if (data.message.statuscode == 201) {
					users.value.push(user.value);
					user.value.blocked = user.value.blockedbool ? "true" : "false";
					user.value.user_id = data.message.result;
					toast.add({ severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000 });
				}
				else if (data.message.statuscode == 409)
					toast.add({ severity: 'error', summary: 'Error', detail: 'The user already exists.', life: 3000 });
				else
					toast.add({ severity: 'error', summary: 'Error', detail: 'User creation has failed.', life: 3000 });

			} catch (e) {
				openErrorModal(e.message);
			}
			password.value = '';
			password2.value = '';
		}
		userDialog.value = false;
		user.value = { name: '', email: '',  role: '', blocked: '', blockedbool: false, user_id: '' };
		viewUserDialog.value = false;
	}
}

/**
 * Prepare user data for editing
 * @param prod 
 */
function editUser(prod) {
	user.value = { ...prod };
	userDialog.value = true;
	createORedit.value = "Edit Store User";
}

/**
 * Prepare user data for viewing
 */
function viewUser(prod) {
	user.value = { ...prod };
	viewUserDialog.value = true;
}

/**
 * Preparation of data in order for the user to change password
 * @param prod 
 */
function changeUserPassword(prod) {
	password.value = '';
	password2.value = '';
	v$.value.$reset();
	user.value = { ...prod };
	changeUserPasswordDialog.value = true;
}

/**
 * Update user password 
 */
async function savePassword() {
	if (!await v$.value.$validate()) return;

	changeUserPasswordDialog.value = false;
	let userdata = {
		password: password.value
	};
	const accessToken = await auth0.getAccessTokenSilently();
	const storeid = await ApiService.getVuexStoreid(accessToken);
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "managers/" + storeid + "/" + user.value.user_id;

	try {

		const response = await fetch(url, {
			method: 'PATCH',
			body: JSON.stringify(userdata),
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		if (response.status >= 200 && response.status < 300) {
			password.value = '';
			password2.value = '';
			toast.add({ severity: 'success', summary: 'Successful', detail: 'Password has been changed.', life: 3000 });
		}
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Immediately sends request to block and unblock user
 * @param prod 
 */
async function blockunblockUser(prod) {
	user.value = { ...prod };
	let blockunblock = "";
	let blockunblockLabel = "";

	if (user.value.blocked == "true") {
		users.value[findIndexById(user.value.user_id)].blocked = "false";
		users.value[findIndexById(user.value.user_id)].blockedbool = false;
		blockunblock = "false";
		blockunblockLabel = "User is unblocked";
	} else {
		blockunblock = "true";
		users.value[findIndexById(user.value.user_id)].blocked = "true";
		users.value[findIndexById(user.value.user_id)].blockedbool = true;
		blockunblockLabel = "User is blocked";
	}

	let userdata = {
		password: 'forblockunblockuser',
		blocked: blockunblock
	};

	const accessToken = await auth0.getAccessTokenSilently();
	const storeid = await ApiService.getVuexStoreid(accessToken);
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "managers/" + storeid + "/" + user.value.user_id;

	try {
		const response = await fetch(url, {
			method: 'PATCH',
			body: JSON.stringify(userdata),
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});

		if (response.status >= 200 && response.status < 300) {
			toast.add({ severity: 'success', summary: 'Successful', detail: blockunblockLabel, life: 3000 });
		}

		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Find index by id
 * @param id 
 */
function findIndexById(id: string) {
	let index = -1;
	for (let i = 0; i < users.value.length; i++) {
		if (users.value[i].user_id === id) {
			index = i;
			break;
		}
	}
	return index;
}

/**
 * Get all user roles
 * @param accessToken 
 */
const getAllUserRoles = async (accessToken: string) => {
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "sa/userroles";
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		roles.value = parseRoleData(data.message);
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Parse fetched roles
 * @param data 
 */
const parseRoleData = (data) => {
	if (role.value === 'SupportAdmin') {
		// Select only StoreAdmin
		const filteredData = data.filter(item => item.userrole === 'StoreAdmin');
		return filteredData.map((item) => ({
			label: item.userrole,
			value: item.userrole,
			availablefeatureids: item.availablefeatureids,
			manageable: item.manageable
		}));
	} else if(role.value === 'StoreAdmin') {
		// Select all userroles excluding SupportAdmin
		const filteredData = data.filter(item => item.userrole !== 'SupportAdmin');
		return filteredData.map((item) => ({
			label: item.userrole,
			value: item.userrole,
			availablefeatureids: item.availablefeatureids,
			manageable: item.manageable
		}));
	}
}
</script>
