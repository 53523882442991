<template>
  <div class="container profile">
    <!-- <div class="flex align-items-center profile-header"> -->
    <div class="flex flex-wrap justify-content-center profile-header gap-2 pt-4">
      <div class="flex flex-wrap justify-content-center">
        <img :src="user?.picture" alt="User's profile picture" class="border-circle img-fluid profile-picture" />
      </div>
      <div class="">
        <h2>{{ user?.name }}</h2>
        <p class="lead text-muted">Email: {{ user?.email }}</p>
        <p class="lead text-muted">Store name: {{ storename }}</p> 
        <p class="lead text-muted">User role: {{ role }}</p> 
        <!-- <p class="lead text-muted">{{ user['http://protractor.com/storeid'] }}</p> -->
      </div>
    </div>

    <!-- <div class="row">
      <pre v-highlightjs><code class="json">{{ JSON.stringify(user, null, 2) }}</code></pre>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
import ApiService from '../service/api.service';
import { ref } from "@vue/reactivity";
import { onBeforeMount } from 'vue';

const storeid = ref('');
const storename = ref('');
const role = ref('');

const { user } = useAuth0();

onBeforeMount(async () => {
  const auth0 = useAuth0();

  const accessToken = await auth0.getAccessTokenSilently();

  storeid.value = await ApiService.getVuexStoreid(accessToken);

  storename.value = await ApiService.getStoreName(accessToken, storeid.value);
  role.value = await ApiService.getRole(accessToken);

}
);

</script>

