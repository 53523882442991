<template>
	<ErrorModal />
	<Toast />

	<div class="card-container">
		<div class="top">
			<div class="head">
				<h2 class="header">Stores</h2>
			</div>
		</div>

		<div class="bottom">
			<div class="search">
				<span class="p-input-icon-left field-container">
					<i class="pi pi-search" />
					<InputText v-model="filters['global'].value" placeholder="Search" />
					<Button type="button" icon="pi pi-times" class="p-button-text clear" @click="clearFilter()" />
				</span>
			</div>
			<div class="create">
				<Button label="Create Store" class="btn-create" @click="openNew" />
			</div>
		</div>
	</div>

	<div class="container flex-grow-1 stores">

		<DataTable :onPage="handlePageChange" ref="dt" :value="stores" dataKey="id" :paginator="true" :rows="10" :filters="filters" stripedRows responsiveLayout="stack" breakpoint="640px"
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
			:rowsPerPageOptions="[5, 10, 25]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} stores" >
			<Column field="storename" header="Store Name" :sortable="true" class="name"></Column>
			<Column field="storestatus" class="status" header="Status" :sortable="true">
				<template #body="slotProps">
					<Button label="Enabled" class="p-button-rounded p-button-success btn-blocked"
						:class="slotProps.data.storestatus" @click="blockunblockstore(slotProps.data)"
						v-if="slotProps.data.storestatus == 'Enable'" />
					<Button label="Disabled" class="p-button-rounded p-button-danger btn-blocked"
						:class="slotProps.data.storestatus" @click="blockunblockstore(slotProps.data)" v-else />
				</template>
			</Column>
			<Column field="view" header=" " :sortable="false" class="view">
				<template #body="slotProps">
					<Button label="View" class="p-button-rounded btn-view secondary" @click="viewstore(slotProps.data)" />
					<Button label="Edit" class="p-button-rounded btn-edit secondary" @click="editstore(slotProps.data)" />
				</template>
			</Column>
			<Column field="validate" header=" " :sortable="false" class="connection">
				<template #body="slotProps">
					<template v-if="!slotProps.data.pinged">
						<Button v-if="!slotProps.data.pinging" label="Test Connection" class="p-button-rounded btn-view tertiary sml" @click="setStoreDetails(slotProps.data, slotProps.index)" />
						<Button v-if="slotProps.data.pinging" label="Testing" class="p-button-rounded btn-view primary sml" :loading="checkStoreValidityLoader" />
					</template>
					<template v-else>
						<Button v-if="slotProps.data.connected" label="Connected" class="p-button-rounded p-button-success sml" />
						<Button v-if="!slotProps.data.connected" label="Not Connected" class="p-button-rounded p-button-danger sml" />
					</template>
				</template>
			</Column>
		</DataTable>
		<Dialog v-model:visible="createStoreDialog" header="Create Store" :modal="true" class="p-fluid">
			<div class="field">
				<label for="storename">Store Name<span class="req">*</span></label>
				<InputText id="storename" v-model.trim="store.storename" :class="{ 'p-invalid': submitted && !store.storename }" />
				<span class="error" v-if="v$.storename.$error">{{ v$.storename.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="locationname">Location Name<span class="req">*</span></label>
				<InputText id="locationname" v-model.trim="store.locationname" :class="{ 'p-invalid': submitted && !store.locationname }" />
				<span class="error" v-if="v$.locationname.$error">{{ v$.locationname.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="connectionid">Connection ID<span class="req">*</span></label>
				<InputText id="connectionid" v-model.trim="store.connectionid" :class="{ 'p-invalid': submitted && !store.connectionid }" />
				<span class="error" v-if="v$.connectionid.$error">{{ v$.connectionid.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="apikey">API Key<span class="req">*</span></label>
				<InputText id="apikey" v-model.trim="store.apikey" :class="{ 'p-invalid': submitted && !store.apikey }" />
				<span class="error" v-if="v$.apikey.$error">{{ v$.apikey.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="authenticationstring">Authentication String<span class="req">*</span></label>
				<InputText id="authenticationstring" v-model.trim="store.authenticationstring" :class="{ 'p-invalid': submitted && !store.authenticationstring }" />
				<span class="error" v-if="v$.authenticationstring.$error">{{ v$.authenticationstring.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="id">Location ID<span class="req">*</span></label>
				<InputText id="id" v-model.trim="store.id" />
				<span class="error" v-if="v$.id.$error">{{ v$.id.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="features">Features</label>
				<div v-for="(feature, index) in setSelectedFeaturesCreateStore(features)" :key="index" class="field-checkbox">
					<Checkbox :id="index" name="feature" :value="feature" v-model="selectedFeatures" :disabled="!feature.manageable"/>
					<label :for="feature.featurename">{{ feature.featurename }}</label>
				</div>
			</div>
			<div class="field">
				<label>Status</label>
				<div class="formgrid grid">
					<div class="field-radiobutton col-12">
						<InputSwitch v-model="store.storestatusbool" style="border-radius: 1.5em;"/>
						<label for="status">{{ store.storestatusbool ? 'Enabled' : 'Disabled' }}</label>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Cancel" class="p-button-rounded btn-cancel secondary" @click="hideDialog" />
				<Button label="Save" class="p-button-rounded btn-save primary" @click="createStore" />
			</template>
		</Dialog>
		<Dialog v-model:visible="updateStoreDialog" header="Edit Store" :modal="true" class="p-fluid">
			<div class="field">
				<label for="storename">Store Name</label>
				<InputText id="storename" v-model.trim="store.storename" disabled="true" :class="{ 'p-invalid': submitted && !store.storename }" />
				<span class="error" v-if="v$.storename.$error">{{ v$.storename.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="locationname">Location Name</label>
				<InputText id="locationname" v-model.trim="store.locationname" disabled="true" :class="{ 'p-invalid': submitted && !store.locationname }" />
				<span class="error" v-if="v$.locationname.$error">{{ v$.locationname.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="connectionid">Connection ID</label>
				<InputText id="connectionid" v-model.trim="store.connectionid" disabled="true" :class="{ 'p-invalid': submitted && !store.connectionid }" />
				<span class="error" v-if="v$.connectionid.$error">{{ v$.connectionid.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="apikey">API Key</label>
				<InputText id="apikey" v-model.trim="store.apikey" disabled="true" :class="{ 'p-invalid': submitted && !store.apikey }" />
				<span class="error" v-if="v$.apikey.$error">{{ v$.apikey.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="authenticationstring">Authentication String</label>
				<InputText id="authenticationstring" v-model.trim="store.authenticationstring" disabled="true" :class="{ 'p-invalid': submitted && !store.authenticationstring }" />
				<span class="error" v-if="v$.authenticationstring.$error">{{ v$.authenticationstring.$errors[0].$message }}</span>
			</div>
			<div class="field">
				<label for="id">Location ID</label>
				<div class="storeid">
					<InputText id="id" v-model.trim="store.id" disabled="true" />
					<span class="error" v-if="v$.id.$error">{{ v$.id.$errors[0].$message }}</span>
				</div>
			</div>
			<div class="field">
				<label for="features">Features</label>
				<div v-for="(feature, index) in setSelectedFeatures(features, store.managedfeatureids)" :key="index" class="field-checkbox">
					<Checkbox :id="index" name="feature" :value="feature" v-model="selectedFeatures" :disabled="!feature.manageable"/>
					<label :for="feature.featurename">{{ feature.featurename }}</label>
				</div>
			</div>
			<div class="field">
				<label>Status</label>
				<div class="formgrid grid">
					<div class="field-radiobutton col-12">
						<InputSwitch v-model="store.storestatusbool" style="border-radius: 1.5em;"/>
						<label for="status">{{ store.storestatusbool ? 'Enabled' : 'Disabled' }}</label>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Cancel" class="p-button-rounded btn-cancel secondary" @click="hideDialog" />
				<Button label="Save" class="p-button-rounded btn-save primary" @click="updateStore" />
			</template>
		</Dialog>
		<Dialog v-model:visible="viewstoreDialog" header="View Store Details" :modal="true" class="p-fluid">
			<div class="data">
				<p><strong>Store Name:</strong><br /><span>{{ store.storename }}</span></p>
			</div>
			<div class="data">
				<p><strong>Location Name:</strong><br /><span>{{ store.locationname }}</span></p>
			</div>
			<div class="data">
				<p><strong>Location ID:</strong><br /><span>{{ store.id }}</span></p>
			</div>
			<div class="data">
				<p><strong>Connection ID:</strong><br /><span>{{ store.connectionid }}</span></p>
			</div>
			<div class="data">
				<p><strong>API Key:</strong><br /><span>{{ store.apikey }}</span></p>
			</div>
			<div class="data">
				<p><strong>Authentication String:</strong><br /><span>{{ store.authenticationstring }}</span></p>
			</div>
			<div class="data">
				<p><strong>Features:</strong><br />
					<ul class="features" style="list-style-type: none; padding-left: 0; margin-top: 0">
						<li v-for="(feature, index) in setManagedFeatureIds(store.managedfeatureids)" :key="index">
							<span>{{ feature.featurename }}</span>
						</li>
					</ul>
				</p>
			</div>
			<div class="data">
				<p><strong>Status:</strong><br />
					<span v-if="store.storestatus == 'Enable'" class="p-button-rounded btn-blocked">Enabled</span>
					<span v-else label="Disabled" class="p-button-rounded btn-blocked">Disabled</span>
				</p>
			</div>
			<div class="data" v-if="store.emails.length > 0">
				<p><strong>Emails:</strong><br />
					<ul style="list-style-type: none; padding-left: 0; margin-top: 0">
						<li v-for="(email, index) in store.emails" :key="index">
							<span>{{ email }}</span>
						</li>
					</ul>
				</p>
			</div>
			<template #footer>
				<Button label="Cancel" class="p-button-rounded btn-cancel secondary"
					@click="hideViewDialog()" />
			</template>
		</Dialog>
	</div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import { useAuth0 } from "@auth0/auth0-vue";
import ApiService from '../service/api.service';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { useRouter } from 'vue-router';
import { HmacSHA1, enc } from 'crypto-js';
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const auth0 = useAuth0();
const storeid = ref('');
const role = ref('');
const roles = ref([]);
const storestatus = ref('');
const selectedFeatures = ref([]);
const selectedfeatureids = ref([]);
const features = ref([]);
const router = useRouter();
const users = ref();

onBeforeMount(async () => {
	const auth0 = useAuth0();
	const accessToken = await auth0.getAccessTokenSilently();
	storeid.value = await ApiService.getVuexStoreid(accessToken);
	role.value = await ApiService.getVuexRole(accessToken);
	storestatus.value = await ApiService.getStoreStatus(accessToken, storeid.value);

	if (storestatus.value == 'Disable' || role.value != 'SupportAdmin') {
		router.push('/storedisable');
	}

	if (role.value === 'SupportAdmin') {
		await getAllFeatures(accessToken);
		await getUserDetails(accessToken);
		await getAllUserRoles(accessToken);
		await getAllStores(accessToken);
	}
});

const toast = useToast();
const dt = ref();
const stores = ref();
const updateStoreDialog = ref(false);
const createStoreDialog = ref(false);
const viewstoreDialog = ref(false);
const store = ref({ id: '', storename: '', locationname: '', connectionid: '', apikey: '', authenticationstring: '', storestatus: '', validate: '', managedfeatureids: '', storestatusbool: false, emails: [] });
const filters = ref({
	'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const submitted = ref(false);
const createORedit = ref('');
const checkStoreValidityLoader = ref(false);
const validationIndex = ref();
const currentPage = ref(0);
const rowsPerPage = ref(10);

/**
 * Clear filter by initializing
 */
function clearFilter() {
	initFilters();
}

/**
 * Initialize filter
 */
function initFilters() {
	filters.value = {
		'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
	};
}

/**
 * Validation of uuid
 * @param value 
 */
function valid1(value: string) {
	const uuid = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(value);
	return uuid;
}

/**
 * Location ID should be the same with UUID removed hypens
 * @param value 
 */
function valid2(value: string) {
	const search = '-';
	const searchRegExp = new RegExp(search, 'g');
	const replaceWith = '';

	if ((value.length == 32) && (store.value.id.replace(searchRegExp, replaceWith) == value))
		return true;
	else
		return false;
}

/**
 * Preparation of rules
 */
const rules = computed(() => ({
	id: { required: helpers.withMessage('Location ID cannot be empty, click the Generate button.', required), valid: helpers.withMessage('Location ID format must be 12345678-xxxx-xxxx-xxxx-1234567890ab', valid1) },
	connectionid: { required: helpers.withMessage('Connection ID cannot be empty.', required), valid: helpers.withMessage('Connection ID must be 32 characters and same as storeid without hyphen -', valid2) },
	apikey: { required: helpers.withMessage('API ID cannot be empty.', required) },
	authenticationstring: { required: helpers.withMessage('Authentication String cannot be empty.', required) },
	storename: { required: helpers.withMessage('Store Name cannot be empty.', required) },
	locationname: { required: helpers.withMessage('Location Name cannot be empty.', required) },
}));

/**
 * Vuelidate initialization
 */
let v$ = useVuelidate(rules, store);

/**
 * Open create store modal
 */
const openNew = () => {
	store.value = { id: '', storename: '', locationname: '', connectionid: '', apikey: '', authenticationstring: '', storestatus: '', validate: '', managedfeatureids: '', storestatusbool: false, emails: [] };
	submitted.value = false;
	createStoreDialog.value = true;
}

/**
 * Hide create store dialog
 */
function hideDialog() {
	v$.value.$reset();
	updateStoreDialog.value = false;
	createStoreDialog.value = false;
	store.value = { id: '', storename: '', locationname: '', connectionid: '', apikey: '', authenticationstring: '', storestatus: '', validate: '', managedfeatureids: '', storestatusbool: false, emails: [] };
	submitted.value = false;
	selectedFeatures.value = [];
	selectedfeatureids.value = [];
}

/**
 * Hide view dialog
 */
function hideViewDialog() {
	viewstoreDialog.value = false;
}

/**
 * Create store functionality
 */
const createStore = async () => {
	if (!await v$.value.$validate()) return;

	if (validateCreds(store.value.connectionid, store.value.apikey, store.value.authenticationstring) == 'Invalid') {
		toast.add({ severity: 'error', summary: 'Fail', detail: 'Store creation has failed, please verify the connection values and try again.', life: 3000 });
		return;
	}

	store.value.validate = validateCreds(store.value.connectionid, store.value.apikey, store.value.authenticationstring);
	store.value.managedfeatureids = selectedFeatures.value.filter((item) => item.manageable === true).map((item) => item.featureid).join(',');

	let storedata = {
		id: store.value.id,
		storename: store.value.storename,
		locationname: store.value.locationname,
		connectionid: store.value.connectionid,
		apikey: store.value.apikey,
		authenticationstring: store.value.authenticationstring,
		managedfeatureids: store.value.managedfeatureids,
		storestatus: store.value.storestatusbool ? 'Enable' : 'Disable'
	};

	const accessToken = await auth0.getAccessTokenSilently();
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "sa/store";

	try {
		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify(storedata),
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		if (response.status == 200) {
			store.value.validate = validateCreds(store.value.connectionid, store.value.apikey, store.value.authenticationstring);
			store.value.storestatus = store.value.storestatusbool ? 'Enable' : 'Disable';
			stores.value.push(store.value);
			toast.add({ severity: 'success', summary: 'Successful', detail: 'Store creation has been successful.', life: 3000 });
			
			// reinitialize the fields
			v$ = useVuelidate(rules, store)
			store.value = { id: '', storename: '', locationname: '', connectionid: '', apikey: '', authenticationstring: '', storestatus: '', validate: '', managedfeatureids: '', storestatusbool: false, emails: [] };
			submitted.value = false;
			selectedFeatures.value = [];
			selectedfeatureids.value = [];
			createStoreDialog.value = false;
		}
		else
			toast.add({ severity: 'error', summary: 'Fail', detail: 'Store creation has failed, the store was already existed', life: 3000 });
	} catch (e) {
		openErrorModal(e.message);
		toast.add({ severity: 'error', summary: 'Fail', detail: 'Store creation has failed', life: 3000 });
	}
}

/**
 * Update store
 */
const updateStore = async () => {
	submitted.value = true;

	store.value.validate = validateCreds(store.value.connectionid, store.value.apikey, store.value.authenticationstring);
	store.value.managedfeatureids = selectedFeatures.value.filter((item) => item.manageable === true).map((item) => item.featureid).join(',');
	const indexToUpdate = stores.value.findIndex((item) => item.id === store.value.id);
	store.value.storestatus = store.value.storestatusbool ? 'Enable' : 'Disable'
	stores.value[indexToUpdate] = store.value;

	let storedata = {
		id: store.value.id,
		storename: store.value.storename,
		locationname: store.value.locationname,
		connectionid: store.value.connectionid,
		apikey: store.value.apikey,
		authenticationstring: store.value.authenticationstring,
		managedfeatureids: store.value.managedfeatureids,
		storestatus: store.value.storestatusbool ? 'Enable' : 'Disable'
	};
	const accessToken = await auth0.getAccessTokenSilently();
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "sa/store/" + store.value.id;

	try {
		const response = await fetch(url, {
			method: 'PATCH',
			body: JSON.stringify(storedata),
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		if (response.status == 200) {
			toast.add({ severity: 'success', summary: 'Successful', detail: 'Store has been updated.', life: 3000 });
		}
		else
			toast.add({ severity: 'error', summary: 'Error', detail: 'Store edit error.', life: 3000 });
	} catch (e) {
		openErrorModal(e.message);
		toast.add({ severity: 'error', summary: 'Error', detail: 'Store edit error.', life: 3000 });
	}
	updateStoreDialog.value = false;
	store.value = { id: '', storename: '', locationname: '', connectionid: '', apikey: '', authenticationstring: '', storestatus: '', validate: '', managedfeatureids: '', storestatusbool: false, emails: [] };
	viewstoreDialog.value = false;
}

/**
 * Prepare data for editing
 * @param prod 
 */
function editstore(prod) {
	store.value = { ...prod };
	updateStoreDialog.value = true;
	createORedit.value = "Edit Store";
	selectedFeatures.value = [];
	selectedfeatureids.value = [];
}

/**
 * Prepare data for viewing
 * @param prod 
 */
function viewstore(prod) {
	store.value = { ...prod };
	viewstoreDialog.value = true;
}

/**
 * Immediately sends request to block and unblock user
 * @param prod 
 */
async function blockunblockstore(prod) {
	store.value = { ...prod };
	let blockunblock = "";
	let blockunblockLabel = "";

	if (store.value.storestatus == "Enable") {
		stores.value[findIndexById(store.value.id)].storestatus = "Disable";
		stores.value[findIndexById(store.value.id)].storestatusbool = false;
		blockunblock = "Disable";
		blockunblockLabel = "store is disabled";
	} else {
		stores.value[findIndexById(store.value.id)].storestatus = "Enable";
		stores.value[findIndexById(store.value.id)].storestatusbool = true;
		blockunblock = "Enable";
		blockunblockLabel = "store is enabled";
	}

	let storedata = {
		id: store.value.id,
		storestatus: blockunblock
	};
	const accessToken = await auth0.getAccessTokenSilently();
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "sa/store/" + store.value.id;

	try {
		const response = await fetch(url, {
			method: 'PATCH',
			body: JSON.stringify(storedata),
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		if (response.status >= 200 && response.status < 300) {
			toast.add({ severity: 'success', summary: 'Successful', detail: blockunblockLabel, life: 3000 });
		}
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Find index by id
 * @param id 
 */
function findIndexById(id: string) {
	let index = -1;
	for (let i = 0; i < stores.value.length; i++) {
		if (stores.value[i].id === id) {
			index = i;
			break;
		}
	}

	return index;
}

/**
 * Validation of connection id, api key, and authentication string
 * @param connectionid 
 * @param apikey 
 * @param authenticationstring 
 */
function validateCreds(connectionid: string, apikey: string, authenticationstring: string) {
	if (enc.Base64.stringify((HmacSHA1(connectionid, apikey))) === authenticationstring)
		return "Valid";
	else
		return "Invalid";
}

/**
 * Get all features
 * @param accessToken 
 */
const getAllFeatures = async (accessToken: string) => {
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "sa/features";
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		features.value = data.message;
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Get all users using store id
 * @param accessToken 
 */
 const getUserDetails = async (accessToken: string) => {
	const Userurl = process.env.VUE_APP_NODEJS_BASE_URL + "sa/" + storeid.value; //to get storeadmin users
	try {
		const response = await fetch(Userurl, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			}
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		users.value = data.message;
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Get all user roles
 * @param accessToken 
 */
const getAllUserRoles = async (accessToken: string) => {
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "sa/userroles";
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		roles.value = parseRoleData(data.message);
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Get all stores
 * @param accessToken 
 */
const getAllStores = async (accessToken: string) => {
	const storeurl = process.env.VUE_APP_NODEJS_BASE_URL + "sa/store/all";

	try {
		const response = await fetch(storeurl, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			}
		});
		const data = await response.json();
		if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		stores.value = data.message;
		stores.value.map(async (item) => {
			item.pinged = false;
			item.pinging = false;
			item.connected = false;
			item.validate = validateCreds(item.connectionid, item.apikey, item.authenticationstring);
			item.storestatusbool = item.storestatus === 'Enable';
			item.emails = users.value.filter((userItems) => userItems.storeid === item.id).map((userItems) => userItems.email);
		});
	} catch (e) {
		openErrorModal(e.message);
	}
}

/**
 * Parse fetched roles
 * @param data 
 */
const parseRoleData = (data) => {
	return data.map((item) => ({
		label: item.userrole,
		value: item.userrole,
		availablefeatureids: item.availablefeatureids,
		manageable: item.manageable
	}));
}

/**
 * Set features for create store modal
 * @param featureItems 
 */
const setSelectedFeaturesCreateStore = (featureItems) => {
	featureItems.forEach((feature) => {
		if (!feature.manageable && !selectedfeatureids.value.includes(feature.featureid)) {
			selectedfeatureids.value.push(feature.featureid);
			selectedFeatures.value.push(feature);
		}
	});
	return featureItems;
}

/**
 * Create and edit store should parse the selected features
 * This will set the default feature to be selected initially on create and update form
 * @param featureItems 
 * @param managedfeatureids 
 */
const setSelectedFeatures = (featureItems, managedfeatureids) => {
	if (managedfeatureids === null) {
		featureItems.forEach((feature) => {
			if (!feature.manageable && !selectedfeatureids.value.includes(feature.featureid)) {
				selectedfeatureids.value.push(feature.featureid);
				selectedFeatures.value.push(feature);
			}
		});
		return featureItems
	}

	featureItems.forEach((feature) => {
		if ((managedfeatureids.includes(feature.featureid) && !selectedfeatureids.value.includes(feature.featureid)) ||
			!feature.manageable && !selectedfeatureids.value.includes(feature.featureid)) {
			selectedfeatureids.value.push(feature.featureid);
			selectedFeatures.value.push(feature);
		}
	});
	return featureItems;
}

/**
 * Set managed feature ids
 * @param managedfeatureids 
 */
const setManagedFeatureIds = (managedfeatureids) => {
	// if the Store detail has no managedfeatureids then select all non-manageable feature in features table
	if (managedfeatureids === null) {
		// get all non-manageable features, it is accessible by default
		return features.value.filter((feature) => feature.manageable === false);
	}

	// if the store has managedfeatureids, then select only saved managedfeatureids and all non-manageable feature in features table
	const managedFeatureIds = managedfeatureids.split(',').map(Number);
	return features.value.filter((feature) => managedFeatureIds.includes(feature.featureid) || feature.manageable === false);
}

/**
 * Set view validity dialog to true
 * @param data 
 * @param index 
 */
const setStoreDetails = (data, index) => {
	if (checkStoreValidityLoader.value === false) {
		store.value = { ...data };
		validationIndex.value = index;
		const storeItemIndex = validationIndex.value + (currentPage.value * rowsPerPage.value);
		stores.value[storeItemIndex].pinging = true;
		checkStoreValidity();
	}
}

/**
 * Send request to apiserver to actually ping 2 major APIs used in DVI and VIN Scanner
 */
const checkStoreValidity = async () => {
	checkStoreValidityLoader.value = true;
	const accessToken = await auth0.getAccessTokenSilently();
	const url = process.env.VUE_APP_NODEJS_BASE_URL + `sa/store/validity/${store.value.id}`;
	const validation = validateCreds(store.value.connectionid, store.value.apikey, store.value.authenticationstring);
	const storeItemIndex = validationIndex.value + (currentPage.value * rowsPerPage.value);

	try {
		const response = await fetch(url, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
		});
		const result = await response.json();
		checkStoreValidityLoader.value = false;
		stores.value[storeItemIndex].pinged = true;
		if (response.status > 400) {
			throw new Error(JSON.stringify(result.error));
		}
		if (validation === 'Valid' && result.message === 'Valid') {
			stores.value[storeItemIndex].connected = true;
			return toast.add({ severity: 'success', summary: `Store is Connected`, detail: 'The store has successfully established a connection.', life: 10000 });
		}
	} catch (e) {
		openErrorModal(e.message);
		stores.value[storeItemIndex].pinging = false;
		stores.value[storeItemIndex].connected = false;
		toast.add({ severity: 'error', summary: `Store is not Connected`, detail: 'Unable to establish a connection for the store.', life: 10000 });
	}
}

/**
 * Set current page and rows per page value on page change
 * @param event 
 */
const handlePageChange = (event) => {
	currentPage.value = event.page;
	rowsPerPage.value = event.rows;
}
</script>
